import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, notification, Spin, Skeleton } from 'antd';

const InterviewNotifications = ({ isNotificaitonLoading, setIsNotificaitonLoading }) => {
    const [interviewNotifications, setInterviewNotifications] = useState([]);
    const [hasFetched, setHasFetched] = useState(false);

    const fetchNotificationsForInterview = async () => {
        setIsNotificaitonLoading(true);
        try {
            let { data } = await axios.get('/getInterviewNotifications');
            console.log(data, 'data');
            setInterviewNotifications(data?.interviewDetail || []);
            setHasFetched(true);
            notification.success({ message: 'Notifications loaded successfully!' });
        } catch (error) {
            console.error('Error fetching notifications:', error);
            notification.error({ message: 'Failed to load notifications', description: error.message });
        } finally {
            setIsNotificaitonLoading(false);
        }
    };

    useEffect(() => {
        fetchNotificationsForInterview();
    }, []);

    return (
        <div>
            <Card title="Interview Notifications" style={{ width: 400, height: 500, overflowY: 'auto' }}>
                {isNotificaitonLoading ? (
                    <>
                        <Skeleton active paragraph={{ rows: 8 }} />
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </>
                ) : (
                    <>
                        {interviewNotifications.length > 0 ? (
                            <ul style={{ listStyle: 'none', padding: 0, marginTop: 10 }}>
                                {interviewNotifications.map((notif, index) => (
                                    <li key={index} style={{ borderBottom: '1px solid #ddd', padding: '8px 0' }}>
                                        <strong>Interviewee:</strong> {notif.interviwee} <br />
                                        <strong>Position:</strong> {notif.position} <br />
                                        <strong>Application Date:</strong> {notif.applicationData} <br />
                                        <strong>Status:</strong> {notif.status}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p style={{ marginTop: 10, textAlign: 'center' }}>No new notifications</p>
                        )}
                        <Button onClick={fetchNotificationsForInterview} type="primary" block>
                            Refresh
                        </Button>
                    </>
                )}
            </Card>
        </div>
    );
};

export default InterviewNotifications;
