import React from 'react';
import { motion } from 'framer-motion';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentAdmin } from '@/redux/auth/selectors';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = window.localStorage.getItem('isLoggedIn'); // Check local storage for login status

  const config = {
    type: 'spring',
    damping: 20,
    stiffness: 100,
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          // Redirect to login if not logged in
          return <Redirect to="/login" />;
        }

    

        // Render the component if logged in and super admin
        return (
          <motion.div
            transition={config}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
          >
            <Component {...props} />
          </motion.div>
        );
      }}
    />
  );
};

export default PrivateRoute;







// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const config = {
//     type: 'spring',
//     damping: 20,
//     stiffness: 100,
//   };

//   return (
//     // Show the component only when the admin is logged in
//     // Otherwise, redirect the admin to /signin page

//     <Route
//       {...rest}
//       render={(props) =>
//         window.localStorage.getItem('isLoggedIn') ? (
//           <motion.div
//             transition={config}
//             initial={{ y: 20, opacity: 0 }}
//             animate={{ y: 0, opacity: 1 }}
//             exit={{ y: -20, opacity: 0 }}
//           >
//             <Component {...props} />
//           </motion.div>
//         ) : (
//           <Redirect to="/login" />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;
